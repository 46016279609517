<template>
  <div style="padding:64px 0 43px 0;text-align:center">
    <div class="primary body4-bold" style="margin-bottom:12px">Step 0{{ step }}</div>
    <div class="main subtitle3"><slot></slot></div>
  </div>
</template>

<script>
  export default {
    name: "StepTitle",
    props: {
      step: {
        type: Number
      }
    },
    created() {

    },
    data() {
      return {}
    },
    methods: {}
  }
</script>

<style lang="stylus" scoped>

</style>
